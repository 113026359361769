@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #edf2f8;
  --secondary-color: #313bac;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
}
::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

::-webkit-scrollbar-track {

  background: rgba(255, 255, 255, 0.25);
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background: #B9B5B5;

  border: 0px solid #fef4f5;
}

::-webkit-scrollbar-thumb:hover {
  background: #979595;
}
  @media screen and (min-width: 1200px) {
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

section .wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: url("assets/wave.png");
  background-size: 1000px 100px;

}

@keyframes gradient-bg {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }

}


section .wave.water {
  animation: animate 25s linear infinite;
  z-index: 1000;
  opacity: 1;
  animation-delay: 0s;
  bottom: 0;

}

section .wave.water:nth-child(2) {
  animation: animate2 15s linear infinite;
  z-index: 999;
  opacity: 0.5;
  animation-delay: -5s;
  bottom: 10px;

}

section .wave.water:nth-child(3) {
  animation: animate 30s cubic-bezier(.55, .5, .45, .5) infinite;
  z-index: 998;
  opacity: 0.2;
  animation-delay: -5s;
  bottom: 15px;

}

section .wave.water:nth-child(4) {
  animation: animate2 5s linear infinite;
  z-index: 997;
  opacity: 0.7;
  animation-delay: -5s;
  bottom: 20px;

}





@keyframes animate {
  0% {
    background-position-x: 0;
  }

  100% {
    background-position-x: 1000px;
  }
}

@keyframes animate2 {
  0% {
    background-position-x: 0;
  }

  100% {
    background-position-x: -1000px;
  }
}
  }

@media (max-width: 1200px){
  * {
      margin: 0;
      padding: 0;
    }
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #a16468;
}

.form-check-inline .form-check-input~.invalid-feedback {
  margin-left: 0.5em;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
  display: block;
}

*.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
  -ms-user-select: none;
  user-select: none;
}