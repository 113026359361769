.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.app__profile-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem;

  img {
    width: 100%;
    // height: 170px;
    border-radius: 15px;
    object-fit: cover;
  }
  .wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: url("../../assets/wave.png");
    background-size: 1000px 100px;
  }

  @keyframes gradient-bg {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }

  section .wave.water {
    animation: animate 25s linear infinite;
    z-index: 1000;
    opacity: 1;
    animation-delay: 0s;
    bottom: 0;
  }

  section .wave.water:nth-child(2) {
    animation: animate2 15s linear infinite;
    z-index: 999;
    opacity: 0.5;
    animation-delay: -5s;
    bottom: 10px;
  }

  section .wave.water:nth-child(3) {
    animation: animate 30s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
    z-index: 998;
    opacity: 0.2;
    animation-delay: -5s;
    bottom: 15px;
  }

  section .wave.water:nth-child(4) {
    animation: animate2 5s linear infinite;
    z-index: 997;
    opacity: 0.7;
    animation-delay: -5s;
    bottom: 20px;
  }

  @keyframes animate {
    0% {
      background-position-x: 0;
    }

    100% {
      background-position-x: 1000px;
    }
  }

  @keyframes animate2 {
    0% {
      background-position-x: 0;
    }

    100% {
      background-position-x: -1000px;
    }
  }

  @media (min-width: 800px) and (max-width: 2000px) {
    width: 450px;
  }

  @media screen and (min-width: 2000px) {
    width: 700px;
    margin: 2rem 4rem;

    // img {
    //   height: 320px;
    // }
  }
}
